import React from 'react';
import { useSettings } from '@wix/tpa-settings/react';
import { useRouter } from '@wix/tpa-router/react';

import { useDidUpdate } from 'common/hooks';
import settingsParams from 'Groups/settingsParams';
import { ELayoutType } from 'settings/constants';

import { GridLayout } from './GridLayout';
import { ListLayout } from './ListLayout';
import { SidebarLayout } from './SidebarLayout';

export function Layout() {
  const router = useRouter();
  const settings = useSettings();
  const layout = settings.get(settingsParams.groupListLayout);
  const sortBy = settings.get(settingsParams.sortBy);

  useDidUpdate(() => {
    router.go('.', { sort: sortBy });
  }, [layout === ELayoutType.Sidebar, sortBy]);

  switch (layout) {
    case ELayoutType.Grid:
      return <GridLayout data-hook="grid-layout" />;

    case ELayoutType.List:
      return <ListLayout data-hook="list-layout" />;

    default:
    case ELayoutType.Sidebar:
      return <SidebarLayout data-hook="sidebar-layout" />;
  }
}

Layout.displayName = 'Layout';
