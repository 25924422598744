import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { useController } from 'common/context/controller';

import { SearchDrawer } from 'common/components/SearchDrawer';
import { FeedSearchResults } from 'common/components/FeedSearchResults';

import { Show } from 'wui/Show';

import { GroupsSearchResults } from './GroupsSearchResults';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

export function Search({ isOpen, onClose, ...rest }: IProps) {
  const { t } = useTranslation();
  const { search$ } = useController();

  const tabs = [
    { id: 'posts', title: t('groups-web.groups-search.tabs.posts') },
    { id: 'groups', title: t('groups-web.groups-search.tabs.groups') },
  ];

  return (
    <SearchDrawer
      isOpen={isOpen}
      onClose={onClose}
      title={t('groups-web.groups-search.modal.title')}
      placeholder={t('groups-web.groups-search.placeholder')}
      origin="groups_page"
      tabs={tabs}
      {...rest}
    >
      {(query, containerRef, activeTab, handleReset) => (
        <>
          <Show if={activeTab === 'groups'}>
            <GroupsSearchResults
              query={query}
              onReset={handleReset}
              container={containerRef.current}
              origin="groups_page"
            />
          </Show>
          <Show if={activeTab === 'posts'}>
            <FeedSearchResults
              query={query}
              onReset={handleReset}
              container={containerRef.current}
              origin="groups_page"
              searchConfig={{
                onSearch: (query: string) =>
                  search$.queryCentralFeed({ query }),
                onSearchMore: (query: string, offset: number) =>
                  search$.queryCentralFeed({
                    query,
                    paging: {
                      offset,
                    },
                  }),
                emptyTitle: t(
                  'groups-web.central-feed-search.empty-state.title',
                ),
                emptySubtitle: t(
                  'groups-web.central-feed-search.empty-state.subtitle',
                ),
                errorTitle: t(
                  'groups-web.central-feed-search.error-state.title',
                ),
                errorSubtitle: t(
                  'groups-web.central-feed-search.error-state.subtitle',
                ),
                actionLabel: t(
                  'groups-web.central-feed-search.error-state.retry',
                ),
              }}
            />
          </Show>
        </>
      )}
    </SearchDrawer>
  );
}

Search.displayName = 'Search';
