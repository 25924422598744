import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useBi, useTranslation } from '@wix/yoshi-flow-editor';

import { groupsSearchSearchResultSrc111Evid523 } from '@wix/bi-logger-groups-data/v2';

import { selectGroupsStatus } from 'store/selectors';
import { EGroupsNamespace } from 'store/groups/constants';

import { useController } from 'common/context/controller';
import { EGroupPartition } from 'api/groups/types';

import { EmptyState } from 'wui/EmptyState';
import { ErrorState } from 'wui/ErrorState';

import {
  GroupsResultsList,
  GroupsResultsListSkeleton,
} from './GroupsResultsList';

interface IProps {
  query?: string;

  container: HTMLElement | null;
  onReset(): void;
  origin: string;
}

export function GroupsSearchResults({
  query,
  onReset,
  container,
  origin,
}: IProps) {
  const { groups$ } = useController();
  const { t } = useTranslation();
  const bi = useBi();

  const { fetch } = useSelector(selectGroupsStatus(EGroupsNamespace.SEARCH));

  useEffect(() => {
    if (query) {
      searchGroups();
    }
  }, [query]);

  if (!query) {
    return (
      <EmptyState
        bw
        variant="section"
        title={t('groups-web.groups-search.empty-state.title')}
        subtitle={t('groups-web.groups-search.empty-state.subtitle')}
      />
    );
  }

  if (fetch?.loading) {
    return <GroupsResultsListSkeleton height="100%" />;
  }

  if (fetch?.error) {
    bi.report(
      groupsSearchSearchResultSrc111Evid523({
        origin,
        inputText: query,
        responseStatus: 'error',
        tabName: 'groups',
      }),
    );

    return (
      <ErrorState
        bw
        variant="section"
        onRetry={handleRetry}
        buttonProps={{ variant: 'basic', outlined: true }}
        title={t('groups-web.group-search.error-state.title')}
        subtitle={t('groups-web.group-search.error-state.subtitle')}
        actionLabel={t('groups-web.group-search.error-state.retry')}
      />
    );
  }

  return (
    <GroupsResultsList query={query} onReset={onReset} container={container} />
  );

  function handleRetry() {
    searchGroups();
  }

  function searchGroups() {
    groups$.query({
      partition: EGroupPartition.ALL,
      namespace: EGroupsNamespace.SEARCH,
      title: query,
      limit: 15,
    });
  }
}

GroupsSearchResults.displayName = 'GroupsSearchResults';
