import { useSettings } from '@wix/tpa-settings/react';
import { useCurrentStateAndParams } from '@wix/tpa-router/react';

import { EGroupPartition } from 'api/groups/types';

import { ELayoutType } from 'settings/constants';
import settingsParams from 'Groups/settingsParams';

export const groupsScreenMap: { [key in ELayoutType]: string } = {
  [ELayoutType.List]: 'list_layout',
  [ELayoutType.Grid]: 'grid_layout',
  [ELayoutType.Sidebar]: 'sidebar_layout',
  [ELayoutType.ListWidget]: 'list_layout',
  [ELayoutType.SideBySide]: 'side_by_side_layout',
};

const groupsTabNameMap: { [key in EGroupPartition]: string } = {
  [EGroupPartition.ALL]: 'all',
  [EGroupPartition.JOINED]: 'my_groups',
  [EGroupPartition.SUGGESTED]: 'suggested_groups',
};

export function useBiParams() {
  const settings = useSettings();

  const { params } = useCurrentStateAndParams();

  const groupsScreen =
    groupsScreenMap[settings.get(settingsParams.groupListLayout) as ELayoutType];

  const activeTab = (partition: EGroupPartition) => groupsTabNameMap[partition];

  return {
    groupsScreen,
    activeTab,
    groupsScreenWithTab: (partition: EGroupPartition = params.partition) => {
      // partition could be undefined for group list widgets
      if (partition) {
        return `${groupsScreen}_${activeTab(partition)}`;
      }
      return groupsScreen;
    },
  };
}
